<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-7" style="text-align: justify">
        One of the main acidic components of acid rain is sulfuric acid,
        <chemical-latex content="H2SO4\text{.}" />
        Assuming sulfuric acid is the only acid in the acid rain, what volume (in mL) of
        <number-value :value="concKOH" unit="\text{M}" />
        <chemical-latex content="KOH" />
        would be required to titrate a
        <number-value :value="volRain" unit="\text{mL}" />
        sample of acid rain with an
        <chemical-latex content="H2SO4" />
        concentration of
        <number-value :value="concH2SO4" unit="\text{M?}" />
      </p>

      <calculation-input
        v-model="inputs.volKOH"
        class="mb-3"
        prepend-text="$\text{Volume}$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question242',
  components: {
    CalculationInput,
    ChemicalLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        volKOH: null,
      },
    };
  },
  computed: {
    concKOH() {
      return this.taskState.getValueBySymbol('concKOH').content;
    },
    volRain() {
      return this.taskState.getValueBySymbol('volRain').content;
    },
    concH2SO4() {
      return this.taskState.getValueBySymbol('concH2SO4').content;
    },
  },
};
</script>
